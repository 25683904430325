import React, { useEffect, useState } from "react";
import { AxiosGet } from "../../utils/api";

const AllCourses = () => {
  const [course, setCourseData] = useState([]);

  const FetchAllCourses = async () => {
    try {
      const response = await AxiosGet(`/college/course/all`);
      setCourseData(response?.AllCourses);
      // console.log(response?.AllCourses);
      if (response?.success) {
        // console.log("huh");
      } else {
        // console.log("Error in fetching all the Courses");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllCourses();
  }, []);
  return (
    <div>
      <h3 className=" text-2xl font-bold mb-5 text-center rounded-lg p-3">
        All Courses{" "}
      </h3>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Course Name
                </th>
                <th scope="col" className="px-6 py-3">
                  College Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Duration
                </th>
                <th scope="col" className="px-6 py-3">
                  Fees
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="">
              {course.length > 0 &&
                course.map((item, index) => (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {" "}
                      {console.log(item?.AllCourses)}
                      {item?.courseName}
                    </th>
                    <td className="px-6 py-4"> {item?.collegeId?.name}</td>
                    <td className="px-6 py-4">{item?.duration}</td>
                    <td className="px-6 py-4">{item?.fees}</td>
                    <td className="px-6 py-4">
                      <a
                        href="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AllCourses;
