import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import arrow from '../../assets/img/arrow.png';

export default function CollegeBar() {
  const [message, setMessage] = useState('');
  const [currentTab, setCurrentTab] = useState('basic-info');

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/courses')) {
      setCurrentTab('courses');
    } else if (location.pathname.includes('/specialization')) {
      setCurrentTab('specialization');
    } else {
      setCurrentTab('basic-info');
    }
  }, [location.pathname]);

  const handleClickCourses = (e) => {
    if (currentTab === 'basic-info') {
      e.preventDefault();
      setMessage('Please fill in the College Information first.');
    }
  };

  const handleClickSpecialization = (e) => {
    if (currentTab === 'courses') {
      e.preventDefault();
      setMessage('Please fill in the Courses first.');
    }
  };

  return (
    <div className="relative py-1 fixed top-0 w-full h-14 px-4 bg-white flex items-center border-b border-gray-300 shadow-sm">
      <Link
        to=""
        className={`px-6 py-2 border border-gray-400 shadow text-md rounded transition-colors ${
          currentTab === 'basic-info'
            ? 'bg-indigo-600 text-white'
            : 'bg-gray-100 text-black hover:bg-gray-200'
        }`}
      >
        Basic Information
      </Link>
      <img src={arrow} alt="arrow" className="mx-4 h-4" />

      <Link
        to=""
        onClick={handleClickCourses}
        className={`px-6 py-2 border border-gray-400 shadow text-md rounded transition-colors ${
          currentTab === 'courses'
            ? 'bg-indigo-600 text-white'
            : 'bg-gray-100 text-black hover:bg-gray-200'
        }`}
      >
        Courses
      </Link>
      <img src={arrow} alt="arrow" className="mx-4 h-4" />
      <Link
        to =""
        onClick={handleClickSpecialization}
        className={`px-6 py-2 border border-gray-400 shadow text-md rounded transition-colors ${
          currentTab === 'specialization'
            ? 'bg-indigo-600 text-white'
            : 'bg-gray-100 text-black hover:bg-gray-200'
        }`}
      >
        Specialization
      </Link>

      {message && (
        <div className="absolute top-16 left-1/2 transform -translate-x-1/2 w-11/12 max-w-xl bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded shadow-md">
          <div className="flex items-center justify-between">
            <strong className="font-bold">Notice:</strong>
            <button
              onClick={() => setMessage('')}
              className="text-red-700 font-bold hover:text-red-900"
            >
              ✕
            </button>
          </div>
          <span className="block mt-2">{message}</span>
        </div>
      )}
    </div>
  );
}
