import React, { useRef, useEffect } from 'react';

const headingClass = `outline-none text-2xl font-bold w-full my-2 underline decoration-blue-800`;
const paragraphClass = `outline-none text-sm w-full my-2 bg-gray-50 p-4 rounded shadow`;

export default function CoursesForm({ formik }) {

    // Create refs for each input field
    const aboutHeadingRef = useRef();
    const aboutContentRef = useRef();
    const worldwideHeadingRef = useRef();
    const worldwideContentRef = useRef();
    const latestUpdateHeadingRef = useRef();
    const latestUpdateContentRef = useRef();
    const infoCollegeHeadingRef = useRef();
    const infoCollegeContentRef = useRef();
    const collegeHistoryHeadingRef = useRef();
    const collegeHistoryContentRef = useRef();
    const highlightsRefs = useRef([]);
    const coursesAndFeesRefs = useRef([]);
    const departmentsHeadingRef = useRef();
    const departmentsContentRef = useRef();

    useEffect(() => {
        // Ensure refs arrays have correct lengths for default values
        highlightsRefs.current = new Array(highlightsTableData[1].length * 2);
        coursesAndFeesRefs.current = new Array(coursesAndFeesData[2].length * 3);
    }, []);


    const handleCollegeFormSubmit = (e) => {
        e.preventDefault();

        const data = {
            about: {
                heading: aboutHeadingRef.current?.value || '',
                content: aboutContentRef.current?.value || '',
            },
            worldwide: {
                heading: worldwideHeadingRef.current?.value || '',
                content: worldwideContentRef.current?.value || '',
            },
            latestUpdate: {
                heading: latestUpdateHeadingRef.current?.value || '',
                content: latestUpdateContentRef.current?.value || '',
            },
            informationCollege: {
                heading: infoCollegeHeadingRef.current?.value || '',
                content: infoCollegeContentRef.current?.value || '',
            },
            collegeHistory: {
                heading: collegeHistoryHeadingRef.current?.value || '',
                content: collegeHistoryContentRef.current?.value || '',
            },
            highlightsTable: highlightsTableData[1].map((_, index) => ({
                title: highlightsRefs.current[index * 2]?.value || '',
                value: highlightsRefs.current[index * 2 + 1]?.value || '',
            })),
            coursesAndFees: {
                heading: coursesAndFeesRefs.current[0]?.value || '',
                content: coursesAndFeesRefs.current[1]?.value || '',
                table: coursesAndFeesData[2].map((_, index) => ({
                    title: coursesAndFeesRefs.current[index * 3 + 2]?.value || '',
                    value: coursesAndFeesRefs.current[index * 3 + 3]?.value || '',
                    additional: coursesAndFeesRefs.current[index * 3 + 4]?.value || '',
                })),
            },
            departments: {
                heading: departmentsHeadingRef.current?.value || '',
                content: departmentsContentRef.current?.value || '',
            },
        };

        const headingStyle = {
            fontSize: '1.5rem', // text-2xl
            fontWeight: 'bold', // font-bold
            width: '100%', // w-full
            margin: '0.5rem 0', // my-2
            textDecoration: 'underline', // underline
            textDecorationColor: '#1E3A8A' // decoration-blue-800
        };

        const paragraphStyle = {
            fontSize: '0.875rem', // text-sm
            width: '100%', // w-full
            margin: '0.5rem 0', // my-2
            backgroundColor: '#F9FAFB', // bg-gray-50
            padding: '1rem', // p-4
            borderRadius: '0.25rem', // rounded
            boxShadow: '0 1px 2px rgba(0,0,0,0.05)' // shadow
        };

        const tableStyle = {
            width: '100%',
            borderCollapse: 'collapse',
            margin: '1rem 0'
        };

        const tdStyle = {
            border: '1px solid #E5E7EB', // border-gray-200
            padding: '0.5rem' // p-2
        };

        const generatedHTML = `
        <div>
            <h2 style="${Object.entries(headingStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.about.heading}</h2>
            <p style="${Object.entries(paragraphStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.about.content}</p>

            <h2 style="${Object.entries(headingStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.worldwide.heading}</h2>
            <p style="${Object.entries(paragraphStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.worldwide.content}</p>

            <h2 style="${Object.entries(headingStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.latestUpdate.heading}</h2>
            <p style="${Object.entries(paragraphStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.latestUpdate.content}</p>

            <h2 style="${Object.entries(headingStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.informationCollege.heading}</h2>
            <p style="${Object.entries(paragraphStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.informationCollege.content}</p>

            <h2 style="${Object.entries(headingStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.collegeHistory.heading}</h2>
            <p style="${Object.entries(paragraphStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.collegeHistory.content}</p>

            <h2 style="${Object.entries(headingStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.highlightsTable[0]?.title || ''}</h2>
            <table style="${Object.entries(tableStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">
                ${data.highlightsTable.map(row => `
                    <tr>
                        <td style="${Object.entries(tdStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${row.title}</td>
                        <td style="${Object.entries(tdStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${row.value}</td>
                    </tr>
                `).join('')}
            </table>

            <h2 style="${Object.entries(headingStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.coursesAndFees.heading}</h2>
            <p style="${Object.entries(paragraphStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.coursesAndFees.content}</p>
            <table style="${Object.entries(tableStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">
                ${data.coursesAndFees.table.map(row => `
                    <tr>
                        <td style="${Object.entries(tdStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${row.title}</td>
                        <td style="${Object.entries(tdStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${row.value}</td>
                        <td style="${Object.entries(tdStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${row.additional}</td>
                    </tr>
                `).join('')}
            </table>

            <h2 style="${Object.entries(headingStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.departments.heading}</h2>
            <p style="${Object.entries(paragraphStyle).map(([key, value]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}:${value}`).join(';')}">${data.departments.content}</p>
        </div>
    `;

        formik.setFieldValue('content', generatedHTML);
        formik.submitForm();
    };

    return (
        <form className='CollegeForm' onSubmit={handleCollegeFormSubmit}>
            {/* College About  */}
            <input ref={aboutHeadingRef} type='text' className={headingClass} defaultValue={AboutData[0]?.trim() || ''} />
            <textarea ref={aboutContentRef} rows={AboutData[1]?.length / 110 || 1} className={paragraphClass} defaultValue={AboutData[1] || ''} />

            {/* World Wide  */}
            <input ref={worldwideHeadingRef} type='text' className={headingClass} defaultValue={WorldWideData[0]?.trim() || ''} />
            <textarea ref={worldwideContentRef} rows={WorldWideData[1]?.length / 110 || 1} className={paragraphClass} defaultValue={WorldWideData[1] || ''} />

            {/* latestUpdateData  */}
            <input ref={latestUpdateHeadingRef} type='text' className={headingClass} defaultValue={latestUpdateData[0]?.trim() || ''} />
            <textarea ref={latestUpdateContentRef} rows={latestUpdateData[1]?.length / 110 || 1} className={paragraphClass} defaultValue={latestUpdateData[1] || ''} />

            {/* informationCollegeData  */}
            <input ref={infoCollegeHeadingRef} type='text' className={headingClass} defaultValue={informationCollegeData[0]?.trim() || ''} />
            <textarea ref={infoCollegeContentRef} rows={informationCollegeData[1]?.length / 105 || 1} className={paragraphClass} defaultValue={informationCollegeData[1] || ''} />

            {/* College History  */}
            <input ref={collegeHistoryHeadingRef} type='text' className={headingClass} defaultValue={collegeHistoryData[0]?.trim() || ''} />
            <textarea ref={collegeHistoryContentRef} rows={collegeHistoryData[1]?.length / 110 || 1} className={paragraphClass} defaultValue={collegeHistoryData[1] || ''} />

            {/* Highlights table  */}
            <input ref={el => highlightsRefs.current[0] = el} type='text' className={headingClass} defaultValue={highlightsTableData[0]?.trim() || ''} />
            <table className='w-[70vw]'>
                <tbody>
                    {highlightsTableData[1].map((row, index) => (
                        <tr key={index} className={index % 2 === 0 && 'bg-gray-200'}>
                            <td>
                                <input
                                    ref={el => highlightsRefs.current[index * 2] = el}
                                    type='text'
                                    className='text-sm outline-none px-4 py-2 bg-transparent w-full h-10'
                                    defaultValue={row.title || ''}
                                />
                            </td>
                            <td>
                                <input
                                    ref={el => highlightsRefs.current[index * 2 + 1] = el}
                                    type='text'
                                    className='text-sm outline-none px-4 py-2 bg-transparent w-full h-10'
                                    defaultValue={row.value || ''}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Courses & Fees Data &  table  */}
            <input ref={el => coursesAndFeesRefs.current[0] = el} type='text' className={headingClass} defaultValue={coursesAndFeesData[0]?.trim() || ''} />
            <textarea ref={el => coursesAndFeesRefs.current[1] = el} rows={coursesAndFeesData[1]?.length / 100 || 1} className={paragraphClass} defaultValue={coursesAndFeesData[1] || ''} />
            <table className='w-[80vw]'>
                <tbody>
                    {coursesAndFeesData[2].map((row, index) => (
                        <tr key={index} className={index % 2 === 0 && 'bg-gray-200'}>
                            <td>
                                <input
                                    ref={el => coursesAndFeesRefs.current[index * 3 + 2] = el}
                                    type='text'
                                    className='text-sm outline-none px-4 py-2 bg-transparent w-full h-10'
                                    defaultValue={row.title || ''}
                                />
                            </td>
                            <td className='w-[900px]'>
                                <textarea
                                    ref={el => coursesAndFeesRefs.current[index * 3 + 3] = el}
                                    type='text'
                                    className='text-sm outline-none px-4 py-2 text-center bg-transparent w-full'
                                    defaultValue={row.value || ''}
                                    rows={row.value?.length / 70 || 1}
                                />
                            </td>
                            <td>
                                <input
                                    ref={el => coursesAndFeesRefs.current[index * 3 + 4] = el}
                                    type='text'
                                    className='text-sm outline-none px-4 py-2 bg-transparent w-full h-10'
                                    defaultValue={row.additional || ''}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* departmentsData  */}
            <input ref={departmentsHeadingRef} type='text' className={headingClass} defaultValue={departmentsData[0]?.trim() || ''} />
            <textarea ref={departmentsContentRef} rows={departmentsData[1]?.length / 80 || 1} className={paragraphClass} defaultValue={departmentsData[1] || ''} />
            <p onClick={handleCollegeFormSubmit} className='text-center py-2 text-2xl bg-green-500 text-white rounded cursor-pointer'>Submit</p>
        </form>
    );
}

const AboutData = [
    'About ',
    `The Indian Organization of Innovation Madras is known both broadly and globally for greatness in specialized training, fundamental and applied research, advancement, business and modern consultancy. A workforce of global notoriety, an exceptionally energetic and splendid understudy local area, superb specialized and supporting staff and a viable organization have all added to the pre-famous status of IIT Madras. The Establishment is pleased to bear the laureate of being No.1 designing college in India. All the more as of late, IIT Madras has been given the title of Foundation of Greatness.

In 1956, the German Government offered specialized help for laying out a foundation of advanced education in designing in India. The principal Indo-German arrangement in Bonn, West Germany for the foundation of the Indian Establishment of Innovation at Madras was endorsed in 1959. 

The Establishment was officially introduced in 1959 by Prof. Humayun Kabir, Association Priest for Logical Exploration and Social Issues. The IIT framework has sixteen Establishments of Innovation - the first of these to be founded were at Kharagpur (estb. 1951), Mumbai (estb. 1958), Chennai (estb. 1959), Kanpur (estb. 1959), Delhi (estb. 1961), Guwahati (estb. 1994) and Roorkee (estb. 1847, joined IITs in 2001).`,
    `A worldwide from IIT Madras`
]
const WorldWideData = [
    'Department and Faculty',
    `IIT Madras is a private establishment with almost 550 workforce, 8000 understudies and 1250 managerial and supporting staff and is an independent grounds situated in a delightful lush place that is known for around 250 hectares. The grounds is situated in the city of Chennai, recently known as Madras. Chennai is the state capital of Tamilnadu, a southern state in India. The Organization has sixteen scholastic divisions and a couple of cutting edge research places in different disciplines of designing and unadulterated sciences, with almost 100 labs coordinated in a novel example. IIT Madras has been the highest level designing establishment in India for four continuous years as well as the 'Best Instructive Organization' in By and large Classification in the NIRF Rankings of 2019 put out by the Service of Human Asset Advancement.

The Organization has sixteen scholastic divisions and a couple of cutting edge research places in different disciplines of designing and unadulterated sciences, with almost 100 labs coordinated in a novel example. IIT Madras has been the highest level designing establishment in India for four continuous years as well as the 'Best Instructive Organization' in By and large Classification in the NIRF Rankings of 2019 put out by the Service of Human Asset Advancement.`
]
const latestUpdateData = [
    'Curriculum and Structure',
    `Remain informed with the most recent news and declarations from IIT Madras. Here are a few ongoing updates:
Affirmations for BTech program in 2024 are currently open. On May 26, 2024, the entrance exam will be given. Enrollment starts on April 21, 2024, and closes on April 30, 2024.
Applications for the BS in Information Science and Applications (On the web) program are being acknowledged. The last date to apply is May 26, 2024, and the selection test is booked for July 07, 2024.
Stage 1 Situations for the year 2024 have closed with more than half of understudies set. The typical bundle offered was INR 19 LPA.`
]
const informationCollegeData = [
    'Career Scope and Opportunities',
    `Remain informed with the most recent news and declarations from IIT Madras. Here are a few ongoing updates:

The BTech program for 2024 admissions are now open. On May 26, 2024, the entrance exam will be given. Enrollment starts on April 21, 2024, and closes on April 30, 2024.
The BS in Data Science and Applications (Online) program is currently accepting applications. The last date to apply is May 26, 2024, and the selection test is booked for July 07, 2024.
Over half of the students in Phase 1 placements for 2024 have been placed. The typical bundle offered was INR 19 LPA.

IIT Madras is the top specialized and research college established in 1959. IIT Madras has consistently been ranked first among India's top engineering schools and in the category as a whole. IIT Madras offers in excess of 100 program through its 19 divisions. IIT Madras is situated over a rambling 250 hectares of grounds and houses north of 550 employees, 8000 understudies, and 1250 regulatory and supporting staff. IIT Madras has marked MOUs with a few unfamiliar colleges for understudy trade programs. According to the MoUs marked, understudies seeking after UG, PG, and Double Degree programs at the foundation spend a semester abroad for wide openness.

The BTech and Online Degree program is the most popular of the 121 IIT Madras courses. BTech is the leader program of the establishment. Admission to the course is done in view of rank acquired in the JEE Progressed test. The base position expected for the IIT Madras BTech program is 42 to 3150. The yearly expense for the BTech program is INR 2.1 Lakhs. 
IIT Madras positions 2023 have been finished up and the most elevated bundle proposed to BTech and MTech programs remained INR 1.31 CPA and INR 49.5 LPA separately. IIT Madras students received 1612 offers for the 2023 placements. There were 258 PPOs available, and American Express, Cogoport, Line Craft, AI, SAP Labs, and others were the best recruiters.
`
]
const collegeHistoryData = [
    'Research and Development',
    `The Indian Establishment of Innovation Madras has a place with the new-age foundations of public significance in higher specialized schooling. Situated in South Chennai, in a lovely grounds crossing 630 sections of land, the Foundation is almost independent and houses around 3000 understudies as well as the groups of workforce and staff individuals. The Organization has become stronger to strength since it got its sanction from the Parliament of India in 1961, and has since secured itself as a chief focal point of examination, consultancy and mechanical turn of events.\n
In 1959, the Organization was officially introduced by Prof. Humayun Kabir, Association Clergyman for Logical and Social Issues. Dr. A. Lakshmanaswamy accepted office as the main Administrator of the Leading body of Lead representatives and Dr. B.Sengupto as the principal Overseer of the Establishment. In 1961, the Foundation was proclaimed as an 'Organization of Public Significance' by the Parliament of India.\n
Achievements that characterized a renowned history
The visit of Dr. Heinrich Lubke, Leader of the Government Republic of Germany, in 1962 denoted the start of the Indo-German Specialized Help Program. In 1973, Shri C.Subramaniam, Pastor for Modern Turn of events and Clergyman for Science and Innovation, initiated the Modern Consultancy Place. That very year, the primary IBM Framework 370 Model 155 advanced PC was devoted to the Organization.\n
Perceiving top notch ability Starting around 1973, the Establishment has presented privileged levels of Specialist of Science and Specialist of Innovation (honoris causa) to famous characters like Nobel Laureate (Physical science) Dr. John Bardeen (1973), previous Priest for International concerns (FRG) Mr. Hans Dietrich Genscher (1973) and previous Director, Nuclear Energy Commission, Dr. Raja Ramanna (1983) among others. Noticeable in the scholastic schedule of the Foundation is the Establishment Conference Day, the meeting address being conveyed normally by the President, VP or Head of the state of India. President Shri Sarvepalli Radhakrishnan tended to the principal meeting of the Establishment. The 35th assembly address (in 1998) was conveyed by the Respectable VP of India, Shri Krishna Kant.
`
]
const highlightsTableData = [
    'Cut off',
    [
        { title: 'Established', value: '1959' },
        { title: 'Campus Area', value: '617 Acres' },
        { title: 'Approved by', value: 'All India Council for Technical Education (AICTE)' },
        { title: 'Institute Type', value: 'Public' },
        { title: 'Cutoff', value: '148 - 3150 rank' },
        { title: 'Total No. Of Courses', value: '16 Departments and 100+ Courses' },
        { title: 'Accepted Entrance Exams', value: 'JEE Main and JEE Advanced scores, GATE, etc.' },
        { title: 'Total No. of Companies Visited', value: '480+ Companies' },
        { title: 'Total No. Of Students Registered', value: '1722' },
        { title: 'Highest Package Offered ', value: 'INR 1.31 CPA' },
        { title: 'Recruiters', value: 'American Express, Cogoport, Minecraft AI, SAP Labs' },
        { title: 'NIRF Ranking', value: '1st Rank Under Overall category, 1st Rank Under Engineering' },

    ]
]

const coursesAndFeesData = [
    'Fees Structure',
    'IIT Madras offers in excess of 100 courses through Undergrad, Postgraduate, and Exploration degrees. Engineering, Sciences, Humanities, and Management are the four streams of instruction. Admission to these courses depends on public level test scores like JEE Progressed, Door, and IIT JAM. The following is a concise about the projects with their qualification:',
    [
        { title: 'Established', value: 'Eligibility: The applicant must have passed 10+2 in the PCM stream with a minimum of 50% marks. Selection Criteria: JEE Advanced + JoSAA Counselling', additional: 'INR 2.1 Lakhs' },
        { title: 'Established', value: 'Eligibility: The applicant must have passed 10+2 in the PCM stream with a minimum of 50% marks. Selection Criteria: JEE Advanced + JoSAA Counselling', additional: 'INR 2.1 Lakhs' },
        { title: 'Established', value: 'Eligibility: The applicant must have passed 10+2 in the PCM stream with a minimum of 50% marks. Selection Criteria: JEE Advanced + JoSAA Counselling', additional: 'INR 2.1 Lakhs' },
        { title: 'Established', value: 'Eligibility: The applicant must have passed 10+2 in the PCM stream with a minimum of 50% marks. Selection Criteria: JEE Advanced + JoSAA Counselling', additional: 'INR 2.1 Lakhs' },

    ]
]

const departmentsData = [
    'Challenges and Emerging Trends',
    `IIT Madras Situations 2023 last report has been delivered alongside the temporary job report. This year, 1612 IIT Madras understudies got position offers from the 480 top organizations. The most elevated situations were accomplished in the Center areas (27.6%), Counseling area (14.3%) trailed by the product area for example 13.2%. The area wise arrangements for IIT Madras are given beneath:

Organization Legitimacy cum-Means Grants
IIT Madras offers the Organization Legitimacy cum-Means (MCM) Grants, giving up to a 67% educational expense waiver. This grant is accessible to 25% of the understudies conceded every year. To be qualified, understudies should have a yearly parental pay of not as much as INR 4.5 Lakhs. The continuation of this grant requires keeping a base GPA of 5.0 in the past semester.

Establishment Free Studentship
IIT Madras offers the Association Authenticity cum-Means (MCM) Awards, surrendering to a 67% instructive cost waiver. This award is open to 25% of the students yielded consistently. To be qualified, students ought to have a yearly parental compensation of not so much as INR 4.5 Lakhs. The continuation of this award requires keeping a base GPA of 5.0 in the past semester.

Foundation Notional Award
IIT Madras offers the Association Authenticity cum-Means (MCM) Awards, surrendering to a 67% instructive cost waiver. This award is open to 25% of the students yielded consistently. To be qualified, students ought to have a yearly parental compensation of not so much as INR 4.5 Lakhs. The continuation of this award requires keeping a base GPA of 5.0 in the past semester.

SC/ST Grant
IIT Madras has a fantastic grant program for students from the SC/ST classes. To qualify, the yearly parental compensation shouldn't outperform INR 4.5 Lakhs. This award integrates a full instructive cost waiver, free wreck workplaces, and a month to month pocket settlement of INR 250. This award is economical, gave the student meets the crucial educational necessities.`,

]

