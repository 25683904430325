import JoditEditor from "jodit-react";
import React, { useRef, useEffect, useState } from "react";
import { AxiosPost, AxiosGet } from "../../utils/api";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import SpecializationForm from "./SpecializationForm";
import CollegeBar from "../college/CollegeBar";

const Specialization = () => {
  const editor = useRef(null);
  const [courses, setCourses] = useState([]);
  const [submittedSpecializations, setSubmittedSpecializations] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State for success popup
  const { collegeId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await AxiosGet(`/college/course/CollegeId/${collegeId}`);
        console.log("API Response:", response);
        setCourses(response?.Course || []);
      } catch (error) {
        console.log("Error fetching courses:", error);
      }
    };

    const loadSubmittedSpecializations = () => {
      const storedSpecializations = localStorage.getItem(
        `submittedSpecializations_${collegeId}`
      );
      if (storedSpecializations) {
        setSubmittedSpecializations(JSON.parse(storedSpecializations));
      }
    };

    if (collegeId) {
      fetchCourses();
      loadSubmittedSpecializations();
    }
  }, [collegeId]);

  // Yup validation schema
  const validationSchema = Yup.object({
    specializationName: Yup.string().required("Specialization Name is required"),
    courseId: Yup.string().required("Please select a course"),
    content: Yup.string().required("Content is required"),
  });

  const formik = useFormik({
    initialValues: {
      specializationName: "",
      courseId: "",
      content: "",
    },
    validationSchema, // Integrate Yup validation schema
    onSubmit: async (values) => {
      const data = {
        specializationName: values.specializationName,
        content: values.content,
        courseId: values.courseId,
      };

      try {
        await AxiosPost("/college/specialization/create", data);

        const newSpecialization = {
          name: values.specializationName,
          content: values.content,
          courseId: values.courseId,
        };

        const updatedSpecializations = [
          ...submittedSpecializations,
          newSpecialization,
        ];

        // Save to local storage
        localStorage.setItem(
          `submittedSpecializations_${collegeId}`,
          JSON.stringify(updatedSpecializations)
        );

        setSubmittedSpecializations(updatedSpecializations);

        console.log("Specialization Submitted:", data);

        // Show success modal and reset form fields
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 3000); // Modal auto-closes after 3 seconds
        formik.resetForm();
      } catch (error) {
        console.log("Error submitting the data:", error);
      }
    },
  });

  return (
    <div>
      <CollegeBar />
      {submittedSpecializations.length > 0 && (
        <div className="mt-10">
          <h3 className="text-xl font-semibold mb-4">Specializations Added</h3>
          <div className="flex flex-wrap gap-4">
            {submittedSpecializations.map((spec, index) => (
              <div
                key={index}
                className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-2"
              >
                <div className="border rounded-lg p-4 shadow-md bg-yellow-300 text-lg font-bold text-center">
                  {spec.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-12">
        <h2 className="bg-red-400 text-white font-bold w-full text-center text-3xl rounded-lg">
          Add Specialization
        </h2>
      </div>

      <div className="ml-10 mt-10">
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-10 mb-4">
            <label className="text-xl mb-4">Select Course</label>
            <br />
            <select
              className="border border-black w-full sm:w-2/3 rounded p-2"
              value={formik.values.courseId}
              onChange={formik.handleChange}
              name="courseId"
            >
              <option value="">Select a course</option>
              {courses.length > 0 ? (
                courses.map((course) => (
                  <option key={course._id} value={course._id}>
                    {course.courseName}
                  </option>
                ))
              ) : (
                <option value="">No courses available</option>
              )}
            </select>
            {formik.errors.courseId && formik.touched.courseId && (
              <div className="text-red-600">{formik.errors.courseId}</div>
            )}
          </div>

          <label className="block text-xl mb-2">Specialization Name</label>
          <input
            className="border border-black w-full sm:w-2/3 rounded p-2"
            type="text"
            value={formik.values.specializationName}
            onChange={formik.handleChange}
            name="specializationName"
            placeholder="Enter Specialization Name"
          />
          {formik.errors.specializationName &&
            formik.touched.specializationName && (
              <div className="text-red-600">
                {formik.errors.specializationName}
              </div>
            )}

          <SpecializationForm formik={formik} />

        </form>

        <div className="flex justify-center">
          <Link
            to="/colleges"
            className="bg-red-600 p-4 mt-10 rounded-lg font-bold text-white text-center"
          >
            All Specializations Completed
          </Link>
        </div>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-green-500">
              Specialization Submitted!
            </h2>
            <p>Your specialization has been successfully submitted.</p>
            <button
              onClick={() => setShowSuccessModal(false)}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Specialization;
