// App.js

import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Courses from "./component/courses/Courses";
import Specialization from "./component/specialization/Specialization";
import AllCourses from "./component/courses/AllCourses";
import AllSpecialization from "./component/specialization/AllSpecialization";




const Dashboard = lazy(() => import("./component/home/dashboard"));
const AllUsers = lazy(() => import("./component/user/AllUsers"));
const AddUser = lazy(() => import("./component/user/AddUser"));
const Error = lazy(() => import("./component/error"));
const Menu = lazy(() => import("./component/home/menu"));
const Login = lazy(() => import("./component/user/login"));
const PRoute = lazy(() => import("./PRoute"));
const Posts = lazy(() => import("./component/posts/Posts"));
const AddPost = lazy(() => import("./component/posts/AddPost"));
const Media = lazy(() => import("./component/media/Media"));
const PostCategory = lazy(() => import("./component/posts/category/Category"));
const PostCategoryCreate = lazy(() => import("./component/posts/category/Create"));
const Colleges = lazy(() => import("./component/college/Colleges"));
const AddCollege = lazy(() => import("./component/college/AddCollege"));
const CollegePosts = lazy(() => import("./component/college/posts/CollegePosts"));
const CollegePostCreate = lazy(() => import("./component/college/posts/CollegePostCreate"));
const AllLeads = lazy(() => import("./component/Leads/AllLeads"));
const UpdateUsers = lazy(() => import("./component/user/UpdateUsers"));
const CollegeCategory = lazy(() => import("./component/college/category/Category"));
const CollegeCategoryCreate = lazy(() => import("./component/college/category/Create"));


function App() {
  return (

    <BrowserRouter>
      <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<PRoute roles={['admin', 'subuser']} />}>
              <Route element={<Menu />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/media" element={<Media />} />
                <Route path="/posts" element={<Posts />} />
                <Route path="/post/category" element={<PostCategory />} />
                <Route path="/post/category/create/:id?" element={<PostCategoryCreate />} />
                <Route path="/post/add/:id?" element={<AddPost />} />
                <Route path="/colleges" element={<Colleges />} />
                <Route path="/college/add/:id?" element={<AddCollege />} />
                <Route path="/college/category/all" element={<CollegeCategory />} />
                <Route path="/college/category/create" element={<CollegeCategoryCreate />} />
                <Route path="/college/posts" element={<CollegePosts />} />
                <Route path="/college/post/add/:id?" element={<CollegePostCreate />} />
                <Route path="/allusers" element={<AllUsers />} />
                <Route path="/adduser" element={<AddUser />} />
                <Route path="/updateUser/:userId" element={<UpdateUsers />} />
                {/* ----------------------Lead Routes-----------------------------------------*/}
                <Route path="/courses/:collegeId" element={<Courses/>}/>
                <Route path="/specialization/:collegeId" element={<Specialization/>}/>
                <Route path="/courses" element={<AllCourses/>}/>
                <Route path="/specialization" element={<AllSpecialization/>}/>

             
           

              </Route>
            </Route>
            <Route path="*" element={<Error />} />
          </Routes>
        </Suspense>
      </React.StrictMode>
    </BrowserRouter>


  );
}

export default App;
