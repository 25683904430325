import React, { useEffect, useState } from "react";
import { AxiosPost } from "../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import CoursesForm from "./CoursesForm";
import { useFormik } from 'formik';
import * as Yup from "yup";
import CollegeBar from "../college/CollegeBar";

// Define the available courses for each level
const coursesByLevel = {
  graduate: ["B.Sc", "B.A", "B.Com"],
  postgraduate: ["M.Sc", "M.A", "M.Com"],
  phd: ["PhD in Computer Science", "PhD in Mathematics"],
  diploma: ["Diploma in Data Science", "Diploma in Graphic Design"],
  certification: ["Certification in Web Development", "Certification in Digital Marketing"]
};

const Courses = () => {
  const { collegeId } = useParams();
  const [courseSubmitted, setCourseSubmitted] = useState(false);
  const [submittedCourses, setSubmittedCourses] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const allCourses = JSON.parse(localStorage.getItem('submittedCourses')) || [];
    const filteredCourses = allCourses.filter(course => course.collegeId === collegeId);
    setSubmittedCourses(filteredCourses);
  }, [collegeId]);

  const handleCompleteCourses = () => {
    navigate(`/specialization/${collegeId}`);
  };

  const formik = useFormik({
    initialValues: {
      courseName: "",
      fees: "",
      duration: "",
      content: "",
      courseLevel: "graduate",
      status: "draft",
      eligibility: "",
      modeOfStudy: "offline", 
      ranking: "",
      cutoff: ""
    },
    validationSchema: Yup.object({
      courseName: Yup.string()
        .required("Course Name is required")
        .min(2, "Course Name must be at least 2 characters"),
      fees: Yup.number()
        .required("Fees are required")
        .min(1, "Fees must be a positive number"),
      duration: Yup.string()
        .required("Duration is required"),
      eligibility: Yup.string()
        .required("Eligibility criteria are required"),
      ranking: Yup.string()
        .required("Ranking is required"),
      cutoff: Yup.string()
        .required("Cutoff is required")
    }),
    onSubmit: async (values) => {
      const data = {
        courseName: values.courseName,
        collegeId,
        fees: values.fees,
        content: values.content,
        duration: values.duration,
        courseLevel: values.courseLevel,
        status: values.status,
        eligibility: values.eligibility,
        modeOfStudy: values.modeOfStudy,
        ranking: values.ranking,
        cutoff: values.cutoff
      };

      try {
        const response = await AxiosPost("/college/course/create", data);
        console.log("Data submitted:", response);

        // Fetch existing courses from local storage and add the new course
        const allCourses = JSON.parse(localStorage.getItem('submittedCourses')) || [];
        const updatedCourses = [...allCourses, data];
        localStorage.setItem('submittedCourses', JSON.stringify(updatedCourses));

        // Filter courses for the current collegeId
        const filteredCourses = updatedCourses.filter(course => course.collegeId === collegeId);
        setSubmittedCourses(filteredCourses);

        setCourseSubmitted(true);
        formik.resetForm();

        // Show the popup
        setShowPopup(true);

        // Automatically close the popup after 3 seconds
        setTimeout(() => setShowPopup(false), 3000);
      } catch (error) {
        console.log("Error submitting the data:", error);
      }
    }
  });

  return (
    <div className="m-auto">
      <CollegeBar />

      {submittedCourses.length > 0 && (
        <div className="mt-10 mb-10">
          <h3 className="text-xl font-bold mb-3">Courses Filled Till Now</h3>
          <ul className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {submittedCourses.map((course, index) => (
              <li
                key={index}
                className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white text-center font-semibold text-lg p-3 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                {course.courseName}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Popup after successful submission */}
      {showPopup && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-lg text-center">
            <h2 className="text-2xl font-bold text-green-600">Course Submitted Successfully!</h2>
            <p>Your course has been successfully added.</p>
            <button 
              onClick={() => setShowPopup(false)} 
              className="bg-green-500 text-white px-4 py-2 rounded mt-4">
              Close
            </button>
          </div>
        </div>
      )}

      <div className="flex mt-12">
        <h2 className="bg-green-400 text-white font-bold w-full text-center text-3xl rounded-lg">
          Add Course
        </h2>
      </div>

      <div className="m-auto max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        <form onSubmit={formik.handleSubmit}>
          {/* Course Level */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Course Level</label>
            <select
              className="border border-gray-300 w-full p-3 rounded-lg"
              value={formik.values.courseLevel}
              onChange={formik.handleChange}
              name="courseLevel"
            >
              <option value="graduate">Graduate</option>
              <option value="postgraduate">Postgraduate</option>
              <option value="phd">PhD</option>
              <option value="diploma">Diploma</option>
              <option value="certification">Certification</option>
            </select>
          </div>

          {/* Course Name */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Course Name</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              value={formik.values.courseName}
              onChange={formik.handleChange}
              placeholder="Enter Course Name"
              name="courseName"
            />
          </div>

          {/* Fees */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Fees</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="number"
              value={formik.values.fees}
              onChange={formik.handleChange}
              placeholder="Enter course fees"
              name="fees"
            />
          </div>

          {/* Duration */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Duration</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="text"
              value={formik.values.duration}
              onChange={formik.handleChange}
              placeholder="Enter course duration (e.g., 3 years)"
              name="duration"
            />
          </div>

          {/* Eligibility */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Eligibility</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="text"
              value={formik.values.eligibility}
              onChange={formik.handleChange}
              placeholder="Enter eligibility criteria"
              name="eligibility"
            />
          </div>

          {/* Mode of Study */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Mode of Study</label>
            <select
              className="border border-gray-300 w-full p-3 rounded-lg"
              value={formik.values.modeOfStudy}
              onChange={formik.handleChange}
              name="modeOfStudy"
            >
              <option value="online">Online</option>
              <option value="offline">Offline</option>
            </select>
          </div>

          {/* Ranking */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Ranking</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="text"
              value={formik.values.ranking}
              onChange={formik.handleChange}
              placeholder="Enter ranking"
              name="ranking"
            />
          </div>

          {/* Cutoff */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Cutoff</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="text"
              value={formik.values.cutoff}
              onChange={formik.handleChange}
              placeholder="Enter cutoff"
              name="cutoff"
            />
          </div>

          {/* Course Information */}
          <CoursesForm formik={formik} />

          {/* Status */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Status</label>
            <select
              className="border border-gray-300 w-full p-3 rounded-lg"
              value={formik.values.status}
              onChange={formik.handleChange}
              name="status"
            >
              <option value="draft">Draft</option>
              <option value="published">Published</option>
            </select>
          </div>

        
        </form>
      </div>

      {/* Button to move to specialization */}
      <div className="flex justify-center mt-8">
        <button
          onClick={handleCompleteCourses}
          className="bg-blue-500 text-white px-6 py-3 rounded-lg font-bold hover:bg-blue-600 transition duration-200"
        >
          Complete Courses
        </button>
      </div>
    </div>
  );
};

export default Courses;
